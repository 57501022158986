"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _warehouseArea = require("@/api/warehouseArea");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
var _default = {
  async created() {
    this.getIdByAreaId().then(id => {
      const url = `/warehouses/${id}/areas/${this.$route.params.areaId}/raw-materials`;
      this.$router.push(url);
    }).catch(error => {
      if (error.status === 404) {
        _elementUi.MessageBox.alert('Invalid ID Url, warehouse area ID is not exist', {
          confirmButtonText: 'Ok'
        }).finally(() => {
          this.$router.push('/warehouses');
          return Promise.resolve('redirected');
        });
      } else {
        return Promise.reject(error);
      }
    });
  },

  methods: {
    getIdByAreaId() {
      // Your fetch API call logic here
      return (0, _warehouseArea.getWarehouseArea)(this.$route.params.areaId).then(warehouseArea => {
        return warehouseArea.data.warehouse.uuid;
      });
    },

    redirectRoute() {
      this.getIdByAreaId().then(id => {
        const url = `/warehouses/${id}/areas/${this.$route.params.areaId}/raw-materials`;
        this.$router.push(url);
      });
    }

  }
};
exports.default = _default;