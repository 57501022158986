var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v("Area: ")]),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.warehouseAreas,
          label: "name",
          reduce: function (warehouseArea) {
            return warehouseArea.uuid
          },
          disabled: _vm.disableWarehouseArea,
        },
        model: {
          value: _vm.listQuery.warehouseAreaId,
          callback: function ($$v) {
            _vm.$set(_vm.listQuery, "warehouseAreaId", $$v)
          },
          expression: "listQuery.warehouseAreaId",
        },
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Raw Material: ")]),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.rawMaterials,
          label: "sku",
          reduce: function (rawMaterial) {
            return rawMaterial.uuid
          },
          disabled: _vm.disableRawMaterial,
        },
        on: {
          open: _vm.onOpenRawMaterialFilter,
          close: _vm.onCloseRawMaterialFilter,
          search: _vm.fetchRawMaterial,
        },
        scopedSlots: _vm._u([
          {
            key: "list-footer",
            fn: function () {
              return [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasNextPage,
                        expression: "hasNextPage",
                      },
                    ],
                    ref: "load",
                    staticClass: "loader",
                  },
                  [_vm._v("\n        Loading more options...\n      ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.listQuery.rawMaterialId,
          callback: function ($$v) {
            _vm.$set(_vm.listQuery, "rawMaterialId", $$v)
          },
          expression: "listQuery.rawMaterialId",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "default" }, on: { click: _vm.back } },
            [_vm._v(_vm._s(_vm.$t("WarehouseRecord.Back")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.WarehouseArea") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.warehouseArea.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.$route.query.rawMaterialId
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("WarehouseRecord.RawMaterial") },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(scope.row.rawMaterial.sku) +
                              " " +
                              _vm._s(
                                scope.row.rawMaterial.sku !==
                                  scope.row.rawMaterial.nameEng
                                  ? scope.row.rawMaterial.nameEng
                                  : null
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2069889295
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.Action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.action) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.QTY") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.qty
                            ? parseFloat(scope.row.qty.toFixed(2))
                            : "0"
                        ) +
                        " " +
                        _vm._s(
                          _vm.pluralize(
                            scope.row.rawMaterial.unit,
                            scope.row.qty
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.Remark") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.remark) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.UpdatedBy") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.updatedBy.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("WarehouseRecord.UpdatedAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.updatedAt) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }