"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _pluralize = _interopRequireDefault(require("pluralize"));

var _warehouseRecord = require("@/api/warehouseRecord");

var _warehouseArea = require("@/api/warehouseArea");

var _rawMaterial = require("@/api/rawMaterial");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _vueSelect = _interopRequireDefault(require("vue-select"));

require("vue-select/src/scss/vue-select.scss");

var _errorParse = require("@/utils/error-parse");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    vSelect: _vueSelect.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        // limit: 10,
        listAll: true,
        warehouseAreaId: null,
        rawMaterialId: null,
        perPage: 10
      },
      warehouseAreas: [],
      rawMaterials: [],
      disableWarehouseArea: false,
      disableRawMaterial: false,
      rawMaterialToShow: 0,
      total: 0
    };
  },

  computed: {
    /* filtered() {
      return this.warehouseAreas
    },
    paginated() {
      return this.warehouseAreas.slice(0, this.limit)
    }, */
    hasNextPage() {
      return this.rawMaterials.length < this.rawMaterialToShow;
    }

  },

  async created() {
    if (this.$route.params.area_id) {
      this.listQuery.warehouseAreaId = this.$route.params.area_id;
      this.disableWarehouseArea = true;
    }

    if (this.disableWarehouseArea) {
      (0, _warehouseArea.getWarehouseArea)(this.listQuery.warehouseAreaId).then(warehouseArea => {
        this.warehouseAreas = [{
          uuid: warehouseArea.data.uuid,
          name: warehouseArea.data.name,
          id: warehouseArea.data.uuid
        }];
      });
    } else {
      (0, _warehouseArea.getWarehouseAreas)({
        limit: 9999,
        warehouseId: this.$route.params.id
      }).then(warehouseAreas => {
        this.warehouseAreas = Object.freeze(warehouseAreas.data.map(warehouseArea => ({
          uuid: warehouseArea.uuid,
          name: warehouseArea.name,
          id: warehouseArea.uuid
        })));
      });
    }
    /* getRawMaterials({ limit: 9999, warehouseId: this.$route.params.id }).then(rawMaterials => {
      this.rawMaterials = rawMaterials.data
    })*/


    this.fetchRawMaterial();

    if (this.$route.query.rawMaterialId) {
      this.listQuery.rawMaterialId = this.$route.query.rawMaterialId;
      this.disableRawMaterial = true;
    }

    this.fetchData();
  },

  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },

  methods: {
    async fetchRawMaterial(keyword = '', loading = null) {
      if (!this.rawMaterialOptions) {
        this.rawMaterialOptions = {
          page: 1
        };
      }

      if (loading) {
        loading(true);
        this.observer.disconnect();
        this.rawMaterialOptions = { ...this.rawMaterialOptions,
          page: 1,
          keyword
        };
      } else {
        this.rawMaterialOptions = { ...this.rawMaterialOptions,
          keyword
        };
      }

      this.rawMaterialOptions = { ...this.rawMaterialOptions,
        keyword,
        uuid: this.$route.query.rawMaterialId
      };

      if (this.$route.params.area_id) {
        this.rawMaterialOptions.warehouseAreaIdByRecord = this.$route.params.area_id;
      } else {
        this.rawMaterialOptions.warehouseId = this.$route.params.id;
      }

      return (0, _rawMaterial.getRawMaterials)(this.rawMaterialOptions).then(response => {
        this.rawMaterialToShow = response.meta.total;

        if (loading) {
          this.rawMaterials = Object.freeze(response.data.map(rawMaterial => ({
            uuid: rawMaterial.uuid,
            sku: rawMaterial.sku,
            id: rawMaterial.uuid
          })));
          this.onOpen();
        } else {
          this.rawMaterials = this.rawMaterials.concat(response.data.map(rawMaterial => ({
            uuid: rawMaterial.uuid,
            sku: rawMaterial.sku,
            id: rawMaterial.uuid
          }))); // console.log('getRawMaterials', this.$route.query.rawMaterialId, this.rawMaterialOptions)
        }
      }).catch(error => console.error(error)).finally(() => {
        if (loading) {
          loading(false);
        }
      });
    },

    async onOpenRawMaterialFilter() {
      if (this.hasNextPage) {
        await this.$nextTick();

        if (this.$refs.load !== undefined) {
          this.observer.observe(this.$refs.load);
        }
      }
    },

    onCloseRawMaterialFilter() {
      this.observer.disconnect();
    },

    async infiniteScroll([{
      isIntersecting,
      target
    }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.rawMaterialOptions.page++;
        await this.fetchRawMaterial();
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },

    async fetchData() {
      this.listQuery.warehouseId = this.$route.params.id;
      this.listLoading = true;
      (0, _warehouseRecord.getWarehouseRecords)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((data, index) => {
          data.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    back() {
      if (this.$route.params.id && this.$route.params.area_id) {
        this.$router.push(`/warehouses/${this.$route.params.id}/areas/${this.$route.params.area_id}/raw-materials`);
      } else if (this.$route.params.id) {
        this.$router.push(`/warehouses/${this.$route.params.id}/areas`);
      } else {
        this.$router.go(-1);
      }
    },

    deleteWarehouseRecord(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('WarehouseRecord.ConfirmDeleteMessage'), _i18n.default.t('WarehouseRecord.Confirm'), {
        confirmButtonText: _i18n.default.t('WarehouseRecord.Delete'),
        cancelButtonText: _i18n.default.t('WarehouseRecord.Cancel'),
        type: 'warning'
      }).then(() => (0, _warehouseRecord.deleteWarehouseRecord)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('WarehouseRecord.Recorddeleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;