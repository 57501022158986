var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "action-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "ID", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm.device !== "mobile" || !_vm.QRScannerAbility
                    ? _c("span", [_vm._v("ID")])
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.ShowQRScanner },
                        },
                        [_c("svg-icon", { attrs: { "icon-class": "qrcode" } })],
                        1
                      ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.HiddenWarehouse") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.hidden
                            ? _vm.$t("Warehouse.No")
                            : _vm.$t("Warehouse.Yes")
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: {
                          to: "/warehouses/" + scope.row.uuid + "/areas",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Warehouse.Areas")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _vm._v(" "),
      _vm.showQRScan
        ? [
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.showQRScan },
                on: {
                  "update:visible": function ($event) {
                    _vm.showQRScan = $event
                  },
                },
              },
              [
                _vm.showQRScan
                  ? _c("vue-qr-reader", {
                      ref: "qr",
                      attrs: {
                        "stop-on-scanned": true,
                        "use-back-camera": true,
                        "draw-on-found": true,
                        "line-color": "#d90000",
                        "line-width": 2,
                        "video-height": 480,
                        "video-width": 640,
                        responsive: true,
                      },
                      on: {
                        "code-scanned": _vm.codeArrived,
                        "error-captured": _vm.errorCaptured,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }