"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vueQrReader = _interopRequireDefault(require("vue-qr-reader/dist/lib/vue-qr-reader.umd"));

var _warehouse = require("@/api/warehouse");

var _warehouseArea = require("@/api/warehouseArea");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { MessageBox, Message } from 'element-ui'
// import i18n from '@/i18n'
var _default = {
  components: {
    Pagination: _Pagination.default,
    VueQrReader: _vueQrReader.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0,
      showQRScan: false,
      QRScannerAbility: true
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _warehouse.getWarehouses)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((data, index) => {
          data.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    ShowQRScanner() {
      this.showQRScan = true;
    },

    async codeArrived(decodedString) {
      // console.log(decodedString)
      // decodedString = 'https://dev3.development.tastelabgroup.com/#/waarehouses/:id/areas/8475ae1a-58dc-4bfa-8430-51dd0a2cc7c6/raw-materials'
      const segments = decodedString.split('/');

      if (segments[5]) {
        this.listLoading = true;
        (0, _warehouseArea.getWarehouseArea)(segments[5]).then(() => {
          this.showQRScan = false;
          segments.shift();
          segments.shift();
          segments.shift();
          segments.shift();
          this.$router.push('/' + segments.join('/'));
        }).catch(error => console.error(error)).finally(() => {
          this.loading = false;
        });
      }
    },

    errorCaptured(error) {
      this.QRScannerAbility = false;
      console.error(error);
    }

  }
};
exports.default = _default;