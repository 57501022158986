"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _warehouseArea = require("@/api/warehouseArea");

var _warehouse = require("@/api/warehouse");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _vuex = require("vuex");

var _errorParse = require("@/utils/error-parse");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        listAll: true
      },
      total: 0
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['user'])
  },

  async created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.listQuery.warehouseId = this.$route.params.id;
      this.listLoading = true;
      (0, _warehouseArea.getWarehouseAreas)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((data, index) => {
          data.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).catch(error => {
        if (error.status === 404) {
          _elementUi.MessageBox.alert('Invalid ID Url, warehouse ID is not exist', {
            confirmButtonText: 'Ok'
          }).finally(() => {
            this.$router.push('/warehouses');
            return Promise.resolve('redirected');
          });
        } else {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },

    editWarehouseArea(id) {
      this.$router.push(`/warehouses/${this.$route.params.id}/areas/${id}/edit`);
    },

    /* goWarehouseAreaRawMaterial(id) {
      this.$router.push(`/warehouses/${this.$route.params.id}/areas/${id}/raw-materials`)
    }, */

    /* goHistory(row) {
      this.$router.push(`/warehouses/${this.$route.params.id}/areas/${row.uuid}/records`)
    }, */
    deleteWarehouseArea(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('WarehouseArea.ConfirmDeleteMessage'), _i18n.default.t('WarehouseArea.Confirm'), {
        confirmButtonText: _i18n.default.t('WarehouseArea.Delete'),
        cancelButtonText: _i18n.default.t('WarehouseArea.Cancel'),
        type: 'warning'
      }).then(() => (0, _warehouseArea.deleteWarehouseArea)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('WarehouseArea.Recorddeleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    downloadQRs() {
      (0, _warehouse.downloadQRs)(this.$route.params.id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${this.$route.params.id}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }

  }
};
exports.default = _default;