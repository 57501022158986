var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-form",
            { attrs: { inline: _vm.device !== "mobile" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--default",
                      attrs: { to: "/warehouses" },
                    },
                    [_vm._v(_vm._s(_vm.$t("Warehouse.Back")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--success is-plain",
                      attrs: {
                        to: "/warehouses/" + _vm.$route.params.id + "/area-add",
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Warehouse.AddWarehouseArea"))
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: { click: _vm.downloadQRs },
                    },
                    [_vm._v(_vm._s(_vm.$t("Warehouse.DownloadQR")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary is-plain",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/warehouses/" +
                            _vm.$route.params.id +
                            "/areas/" +
                            scope.row.uuid +
                            "/raw-materials",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("WarehouseArea.RawMaterial")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: _vm.device === "mobile" ? "mini" : "default",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteWarehouseArea(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("WarehouseArea.Delete")))]
                    ),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return [
                        "Operation Manager",
                        "Admin",
                        "Warehouse Manager",
                      ].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--success",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: {
                              to:
                                "/warehouses/" +
                                _vm.$route.params.id +
                                "/areas/" +
                                scope.row.uuid +
                                "/edit",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(" " + _vm._s(_vm.$t("WarehouseArea.Edit"))),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return [
                        "Operation Manager",
                        "Admin",
                        "Warehouse Manager",
                      ].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--info",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: {
                              to:
                                "/warehouses/" +
                                _vm.$route.params.id +
                                "/areas/" +
                                scope.row.uuid +
                                "/records",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-time" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("WarehouseArea.History"))
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }